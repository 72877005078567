.settings-wrapper {
  width: 60%;
  margin: 90px auto;
}

@media (max-width: 768px) {
  .settings-wrapper {
    width: 95%;
    margin: 40px auto;
    box-sizing: border-box;
  }
}
