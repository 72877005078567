.image-gallery-wrapper {
  width: 100vw;
  margin: auto;
}

.download-wrapper {
  position: relative;
  display: block;
  padding-bottom: 30px;
  margin: auto;
  max-width: 600px;
}
.download-wrapper p {
  max-width: 600px;
  text-align: left;
}

.download-text {
  width: 80%;
}
.download-text strong {
}

.download-icon {
  position: absolute;
  right: 30px;
  margin-left: 10%;
  justify-content: center;
  transition: all 0.2s ease-in;
}

.download-icon:hover {
  transform: scale(1.3);
}

.gridimg {
  height: 200px;
  cursor: pointer;
  padding: 2px 3px;
  border: 1px solid white;
  display: inline-block;
  position: relative;
}
.gridimg img {
  max-height: 100%;
}
.gridimg .imgShadow {
  position: absolute;
  bottom: 0px;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  left: 0px;
  width: 100%;
  height: 60px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}
.gridimg span {
  position: absolute;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  left: 10px;
  bottom: 10px;
  font-size: 10px;
  color: white;
}
.gridimg:hover .imgShadow {
  display: block;
  opacity: 1;
}
.gridimg:hover span {
  display: block;
  opacity: 1;
}

.lightbox {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
}
.lightbox span {
  color: white;
  position: relative;
  display: block;
  margin: 30px auto;
  width: 300px;
  top: 15px;
  left: ;
}
.lightbox .shadow {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.lightbox img {
  max-height: 500px;
  max-width: 95vw;
  z-index: 1;
}
.lightbox .clear {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 32px;
}
.lightbox .rightCont {
  position: fixed;
  top: 50%;
  right: 0px;
  padding: 20px;
  transition: 0.3s all ease-in;
  z-index: 2;
}
.lightbox .leftCont {
  position: fixed;
  top: 50%;
  left: 0px;
  padding: 20px;
  transition: 0.3s all ease-in;
  z-index: 2;
}
.lightbox .leftCont:hover,
.lightbox .leftCont:focus {
  background-color: rgba(255, 255, 255, 0.3);
}
.lightbox .rightCont:hover,
.lightbox .rightCont:focus {
  background-color: rgba(255, 255, 255, 0.3);
}
