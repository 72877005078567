.password-reset-wrapper {
  max-width: 500px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .password-reset-wrapper {
    max-width: 95%;
  }
}
