header.mainheader {
  height: 500px;
  min-height: 500px;
  background-color: #8a4797;
  align-items: center;
}
header.miniheader {
  height: 80px;
  min-height: 80px;
  background-color: #8a4797;
  display: flex;
  align-items: center;
}
header.header span.email {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 10;
  color: #fbbbcc;
  font-weight: 100;
}
.moviecontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 99%;
}
header.header span.logout {
  position: absolute;
  top: 30px;
  left: 10px;
  font-size: 10pt;
  color: #fbbbcc;
  font-weight: 100;
  cursor: pointer;
}
header.header div.user {
}
header.header div.content {
  display: block;
  padding: 70px 10px;
  margin: auto;
  color: #fbbbcc;
}

header.header img {
  width: 200px;
  max-height: 200px;
  display: block;
  margin: auto;
}
header.header p {
  margin: 0;
  margin-left: auto;
  margin-right: auto;
}
header.header h1 {
  margin: 0;
  font-size: 60px;
  color: #f4386d;
  color: #fbbbcc;
}

header.header nav {
  z-index: 1;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 15px;
}
header.header nav a {
  color: white;
}
header.header nav li {
  text-decoration: none;
  display: inline-block;
  float: right;
  background-color: transparent;
  border-radius: 3px;
  opacity: 0.8;
}

header.header nav li.mobilemenu {
  display: none;
}

@media only screen and (max-width: 600px) {
  header.header nav li.mobilemenu {
    /*display: inline-block;*/
  }
  header.header nav a {
    margin: 5px 20px;
  }

  header.header h1 {
    font-size: 40px;
  }
  header.header img {
    width: 100px;
    max-height: 100px;
  }
}

.logo {
  z-index: 0;
  width: 300px;
  padding: 20px;
  display: flex;
  justify-content: left;
  position: absolute;
  top: 7px;
}
.logo.hide {
  display: none;
}

.banner {
  width: 100%;
  padding: 50px 25px;
  background-color: #ff1170;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timer {
  position: relative;
  margin: auto;
  font-size: 1em;
  background: rgba(255, 255, 255, 0.3);
  padding: 61px;
  color: white;
  border-radius: 2px;
  width: 300px;
}
.timer span {
  display: block;
  font-size: 12px;
  margin-top: 10px;
}

.mobile .icon {
  position: absolute;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  right: 16px;
  top: 25px;
  transition: 0.3s all ease-in;
}
#MobileIcon {
  transition: 0.3s all ease-in;
}
#MobileIcon.left {
  right: initial;
  left: 10px;
}
#MobileIcon.left:after {
  content: "";
  height: 100vh;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.5);
}
.mobile {
  display: none;
}

#MobileMenu {
  position: fixed;
  display: block;
  top: 0;
  left: 100%;
  height: 100vh;
  width: 85%;
  background: #eee;
  box-shadow: 1px 1px 1px;
  margin: 0;
  padding: 15px;
  color: #333;
  transition: 0.3s all ease-in;
  z-index: 3;
}

#MobileMenu.left {
  left: 15%;
}
#MobileShadow {
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
}
#MobileShadow.show {
  display: block;
}

header.header nav.mm li {
  float: none;
}
header.header nav.mm a {
  display: block;
  color: #333;
}

@media only screen and (max-width: 600px) {
  header.header nav.menu {
    display: none;
  }
  header.header .logo {
    position: relative;
    width: 150px;
    height: initial;
    max-height: initial;
    max-width: initial;
  }
  .mobile {
    display: initial;
  }
  header.header mobile {
    display: initial;
  }
}
@media (max-width: 830px) {
  header.mainheader {
    height: 360px;
    min-height: 360px;
  }
  header.header div.content {
    padding: 30px 10px;
  }
}

.herobanner {
  display: flex;
  max-height: 500px;
  height: 75vw;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  background-color: #ff1170;
}

.herobanner img {
  max-height: 140%;
}
@media only screen and (min-width: 850px) {
  .herobanner {
    align-items: flex-end;
  }
}
