header.mainheader.hide,
footer.hide {
  display: none;
}

.player-wrapper {
  background-color: black;
  position: relative;
  display: block;
  height: 100vh;
  margin: auto;
}
.player-wrapper a {
  position: absolute;
  top: 10px;
  left: 10px;
}
.player-wrapper .back-button {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: block;
  transition: visibility 1s, opacity 1s;
}
.player-wrapper .hide {
  opacity: 0;
  transition: opacity 1s;
}
.video-react .video-react-big-play-button {
  top: 50%;
  left: 50%;
}

video {
  max-width: 100%;
  max-height: 100vh;
  width: 100%;
  height: 100vh;
}
