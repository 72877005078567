.movie-image {
  width: 300px;
  height: 200px;
  position: relative;
  display: inline-block;
  opacity: 0.95;
  box-shadow: 0px 0px 3px;
  transition: 0.3s all ease-in;
  cursor: pointer;
}
.movie-image .shadow {
  position: absolute;
  bottom: 0;
  height: 66px;
  width: 100%;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.movie-image:hover {
  box-shadow: 0px 0px 5px;
  opacity: 1;
}
.movie-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.movie-image span {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  margin: 10px 15px;
  color: white;
}

@media only screen and (max-width: 600px) {
  .movie-image {
    margin: 15px 0px;
  }
}
