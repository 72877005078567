/*
  From: https://tympanus.net/codrops/2013/08/06/creative-link-effects/
*/

*,
*:after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

nav a {
	position: relative;
	display: inline-block;
	margin: 15px 25px;
	outline: none;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 400;
	text-shadow: 0 0 1px rgba(255,255,255,0.3);
	font-size: 1.35em;
}

nav a:hover,
nav a:focus {
	outline: none;
}

/* Effect 13: three circles */
.cl-effect-13 a {
	-webkit-transition: color 0.3s;
	-moz-transition: color 0.3s;
	transition: color 0.3s;
}

.cl-effect-13 a::before {
	position: absolute;
	top: 100%;
	left: 50%;
	color: transparent;
	content: '•';
	text-shadow: 0 0 transparent;
	font-size: 1.2em;
	-webkit-transition: text-shadow 0.3s, color 0.3s;
	-moz-transition: text-shadow 0.3s, color 0.3s;
	transition: text-shadow 0.3s, color 0.3s;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	transform: translateX(-50%);
	pointer-events: none;
}

.cl-effect-13 a:hover::before,
.cl-effect-13 a:focus::before {
	color: #fff;
	text-shadow: 10px 0 #fff, -10px 0 #fff;
}

.cl-effect-13 a:hover,
.cl-effect-13 a:focus {
	color: #f4386d;
}

