.karneWrapper {
  margin: auto;
  display: block;
  max-width: 1000px;
}

.movieWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head {
  margin: 50px auto;
  display: block;
  /*font-family: sans-serif;
  text-transform: uppercase;*/
  padding-bottom: 50px;
  color: #87509b;
  font-weight: 100;
  text-align: center;
  font-size: 40px;
}
.invdiv .head {
  color: #eeb3bd;
}

.sections {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(8, 200px);
}
@media screen and (max-width: 700px) {
  .sections {
    grid-template-columns: repeat(2, 1fr);
  }
}

.headerStyle {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  justify-self: center;
  align-self: center;
  max-width: 200px;
  transition: 0.3s all ease-in;
  padding: 5px;
  opacity: 1;
}

.headerStyle::after {
  content: "";
  display: block;
  height: 2px;
  width: 0%;
  margin: auto;
  border-radius: 3px;
  margin-top: 10px;
  background-color: red;
  transition: 0.4s all ease-in;
}
.headerStyle:hover::after {
  width: 60%;
}

.headerStyle:hover {
  transform: scale(1.2);
  opacity: 1;
}

.logos {
  max-width: 100px;
  max-height: 100px;
  align-self: center;
}

.pdflink {
  margin: 50px;
}
@media screen and (max-width: 700px) {
  .pdflink {
    display: block;
  }
}

.pdfimg {
  max-height: 300px;
  box-shadow: 0px 0px 0px #fff;
  transition: all 0.3s ease-in;
}
.pdfimg:hover {
  box-shadow: 0px 0px 20px #fff;
}

.invdiv {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 100px;
  padding-bottom: 200px;
  background-color: #87509b;
}

.row {
  padding: 100px 50px;
  min-height: 90vh;
}

hr {
  width: 50%;
  max-width: 400px;
  height: 2px;
  margin-top: 40px;
  margin-bottom: 40px;
}
