.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
  left: 0px;
  transition: 0.4s ease-in all;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: PT sans;
}
li {
  font-variant: all-petite-caps;
}
h1,
h2,
h3,
h4,
h5 {
  font-variant: petite-caps;
  text-transform: uppercase;
}
p {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

@font-face {
  font-family: "Lato-Light";
  src: local("Lato-Light"), url(./../fonts/Lato-Light.ttf) format("ttf");
}
@font-face {
  font-family: "f2";
  src: local("f2"), url(../fonts/Montserrat-Medium.ttf) format("ttf");
}
@font-face {
  font-family: "f3";
  src: local("f3"), url(../fonts/Montserrat-SemiBold.ttf) format("ttf");
}
@font-face {
  font-family: "f4";
  src: local("f4"), url(../fonts/OPTIBrianJamesBoldCond-Bold.otf) format("otf");
}
