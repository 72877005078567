.button {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  transition: 150ms all cubic-bezier(0.455, 0.03, 0.515, 0.955);
  border-radius: 2px;
}

.button:active {
  transform: translateY(3px);
}

.button:hover {
  opacity: 0.8;
}
